<template>
	<label :for="id" class="radio center" :class="{checked: model == value}">
		<input :id="id" v-model="computedModel" :value="value" type="radio">
		<template v-if="$slots.default">
			<slot />
		</template>
		<template v-else>{{label || value}}</template>
	</label>
</template>

<script>
export default {
	props: ["id", "label", "value", "model", "name"],
	model: {
		prop: "model",
		event: "change"
	},
	computed: {
		computedModel: {
			get() {
				return this.model;
			},
			set(val) {
				this.$emit("change", val);
			}
		},
	}
}
</script>

<style lang="scss" scoped>
label {
	margin: 0;
	color: #fff;
	cursor: pointer;
	transition: 0.2s;
	padding: 7px 20px;
	border-radius: 5px;
	letter-spacing: 2px;
	border-radius: 999px;
	background: $theme-light;

	&:hover,
	&.checked {
		box-shadow: 3px 5px 10px #999;
	}

	&.checked {
		background: $theme;
	}
}

input {
	display: none;
	appearance: none;
}
</style>